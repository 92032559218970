<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50 mt-12">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="open" class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-center">
              <div class="w-0 flex-1 flex justify-between">
                <p class="w-0 flex-1 text-sm font-medium text-gray-900">
                  {{ text }}
                </p>
                <button @click="accept" type="button" class="ml-3 flex-shrink-0 bg-white rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  {{ buttonText }}
                </button>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button @click="close" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { XIcon } from '@heroicons/vue/solid'

let component = null;

export default {
  components: {XIcon},
  data() {
    return {
      open: false,
      text: "",
      buttonText: "",
      accept: () => {},
      close: () => {},
    };
  },
  mounted() {
    component = this;
  }
}

export async function notify(text, buttonText, action = () => {}) {
  component.open = true;
  component.text = text;
  component.buttonText = buttonText;

  return await new Promise((resolve) => {

    component.close = () => {
      component.open = false;
      resolve();
    }
    component.accept = () => {
      component.open = false;
      action();
      resolve();
    }
  });

}
</script>
